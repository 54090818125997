// react
import { useEffect } from 'react'
// next
import { useRouter } from 'next/router'
// i18n
import { i18n } from '@lingui/core'
// utils
import { merge } from 'lodash'

// ----------------------------------------------------------------------
/**
 * Load catalog based on locale
 * @param {string} locale
 * @returns {Promise<import('@lingui/core').Messages>}
 */
export async function loadCatalog(locale) {
  const catalog = await import(`@lingui/loader!./locales/${locale}.po`)
  return catalog.messages
}

/**
 * Initialize i18n lingui with catalog message
 * @param {import('@lingui/core').Messages} messages
 * @returns {void}
 */
export function useLinguiInit(messages) {
  const router = useRouter()

  const locale = router.locale || router.defaultLocale
  const isClient = typeof window !== 'undefined'

  if (!isClient && locale !== i18n.locale) {
    // there is single instance of i18n on the server
    // note: on the server, we could have an instance of i18n per supported locale
    // to avoid calling loadAndActivate for (worst case) each request, but right now that's what we do
    i18n.loadAndActivate({ locale, messages })
  }
  if (isClient && !i18n.locale) {
    // first client render
    i18n.loadAndActivate({ locale, messages })
  }

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages })
    }
  }, [locale, messages])
}

// ----------------------------------------------------------------------
// HELPER FOR SSG, SSR FUNCTIONS

/**
 * Inject i18n to SSG, SSR functions
 * @param {import('next').GetStaticPropsContext|import('next').GetServerSidePropsContext} [fn]
 * @returns {import('next').GetStaticPropsResult|import('next').GetServerSidePropsResult)}
 */
export function withI18n(fn = null) {
  return async (ctx) => {
    // parallel load catalog and execute getStaticProps or getServerSideProps function
    const [fnResult, i18n] = await Promise.all([
      // execute getStaticProps or getServerSideProps function
      fn ? fn(ctx) : {},
      // load catalog
      loadCatalog(ctx.locale)
    ])

    // merge the results
    const result = merge({}, fnResult, {
      props: {
        i18n
      }
    })

    return result
  }
}

/**
 * Shortcut for withI18n for pages that doesn't need getStaticProps
 * @type {import('next').GetStaticProps}
 */
export const getStaticProps = withI18n()

/**
 * Shortcut for withI18n for pages that doesn't need getServerSideProps
 * @type {import('next').GetServerSideProps}
 */
export const getServerSideProps = withI18n()
