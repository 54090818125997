// utils
import { get } from 'lodash'

/**
 *
 * @param {Object} user - iser data, normally supplied from `AuthContext`
 * @param {any} args - fetching arguments passed to SWR
 * @returns {[boolean, any]} `[isTokenFound, updatedArgs]`
 */
export function recursivelyProcessUserToken(user, args) {
  // handle array args
  if (Array.isArray(args)) {
    let accITF = false

    const accAgs = args.map((item) => {
      const [itf, a] = recursivelyProcessUserToken(user, item)

      accITF = accITF || itf
      return a
    })

    return [accITF, accAgs]
  }

  if (typeof args === 'string' && args.startsWith('$user.')) {
    const userField = args.replace('$user.', '')

    return [true, get(user, userField)]
  }

  // whoever decides null is an object should gth
  if (
    typeof args === 'object' &&
    args?.constructor === Object &&
    args !== null
  ) {
    let accITF = false
    const accArgs = {}
    Object.entries(args).forEach(([key, val]) => {
      const [itf, a] = recursivelyProcessUserToken(user, val)

      accArgs[key] = a
      accITF = accITF || itf
    })

    return [accITF, accArgs]
  }

  return [false, args]
}
