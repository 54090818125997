import axios from 'axios'
import { getEndpointUrl, serviceGet, servicePost } from './_factory'

const getList = (query, token) => serviceGet('/v1/earnings', query, token)

const getAggregateAmount = (token) =>
  serviceGet('/v1/earnings/aggregates/amount', undefined, token)

const postRequest = (data, token) => servicePost('/v1/earnings', data, token)

//
const getExportCSV = (query, token) =>
  axios.get(getEndpointUrl('/v1/earnings/csv'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params: query,
    responseType: 'blob'
  })

const getExportExcel = (query, token) =>
  axios.get(getEndpointUrl('/v1/earnings/excel'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params: query,
    responseType: 'blob'
  })

const earningService = {
  getList,
  getAggregateAmount,
  postRequest,
  //
  getExportCSV,
  getExportExcel
}

export default earningService
