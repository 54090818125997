import { CloseIcon } from './CustomIcons'

// ----------------------------------------------------------------------

/**
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').Components}
 */
export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />
      },

      styleOverrides: {
        root: {
          borderRadius: '0.25rem'
        },
        filled: {
          fontWeight: 500,
          '&.MuiChip-colorPrimary': {
            backgroundColor: theme.palette.primary.lighter,
            color: theme.palette.primary.dark
          },
          '&.MuiChip-colorSecondary': {
            backgroundColor: theme.palette.secondary.lighter,
            color: theme.palette.secondary.dark
          },
          '&.MuiChip-colorInfo': {
            backgroundColor: theme.palette.info.lighter,
            color: theme.palette.info.dark
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: theme.palette.success.lighter,
            color: theme.palette.success.dark
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: theme.palette.warning.lighter,
            color: theme.palette.warning.dark
          },
          '&.MuiChip-colorError': {
            backgroundColor: theme.palette.error.lighter,
            color: theme.palette.error.dark
          }
        },
        colorDefault: {
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary
          }
        },
        outlined: {
          borderColor: theme.palette.grey[500_32],
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.main
          },
          '&.MuiChip-colorSecondary': {
            borderColor: theme.palette.secondary.main
          }
        },
        //
        avatarColorInfo: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.dark
        },
        avatarColorSuccess: {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.dark
        },
        avatarColorWarning: {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.dark
        },
        avatarColorError: {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark
        }
      }
    }
  }
}
