import axios from 'axios'

import { serviceGet, servicePost } from './_factory'

const getList = (params, token) => serviceGet('/v1/files', params, token)

const createUploadURL = (payload, token) =>
  servicePost('/v1/files', payload, token)

const uploadFile = (uploadUrl, { data, mimeType }) =>
  axios.put(uploadUrl, data, {
    headers: { 'Content-Type': mimeType }
  })

const accessFile = (fileId, params, token) =>
  serviceGet(`/v1/files/${fileId}`, params, token)

const fileService = {
  getList,
  createUploadURL,
  uploadFile,
  accessFile
}

export default fileService
