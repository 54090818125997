import {
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost
} from './_factory'

const getById = (id, token) =>
  serviceGet(`/v1/promo-pages/${id}`, undefined, token)

const getList = (query, token) => serviceGet('/v1/promo-pages', query, token)

const postCreate = (data, token) => servicePost('/v1/promo-pages', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/promo-pages/${id}`, data, token)

const deleteRemove = (id, token) =>
  serviceDelete(`/v1/promo-pages/${id}`, undefined, token)

const promoPageService = {
  getById,
  getList,
  postCreate,
  patchUpdate,
  deleteRemove
}

export default promoPageService
