import { serviceGet } from './_factory'

const getAggregateAmount = (token) =>
  serviceGet('/v1/balances/aggregates/amount', undefined, token)

const balanceService = {
  getAggregateAmount
}

export default balanceService
