import { serviceGet, servicePost } from './_factory'

const getStats = (params, token) =>
  serviceGet('/v1/activities/stats', params, token)

const getCount = (params, token) =>
  serviceGet('/v1/activities/count', params, token)

const getAggregateDate = (params, token) =>
  serviceGet('/v1/activities/aggregates/date', params, token)

const getViewCount = (pageId, token) =>
  serviceGet(`/v1/activities/page-view/${pageId}`, token)

const postSave = (data, token) => servicePost('/v1/activities', data, token)

const activityService = {
  getStats,
  getCount,
  getAggregateDate,
  getViewCount,
  postSave
}

export default activityService
