import {
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost
} from './_factory'

const getById = (id, token) =>
  serviceGet(`/v1/content-pages/${id}`, undefined, token)

const getList = (query, token) => serviceGet('/v1/content-pages', query, token)

const postCreate = (data, token) =>
  servicePost('/v1/content-pages', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/content-pages/${id}`, data, token)

const deleteRemove = (id, token) =>
  serviceDelete(`/v1/content-pages/${id}`, undefined, token)

const contentPages = {
  getById,
  getList,
  postCreate,
  patchUpdate,
  deleteRemove
}

export default contentPages
