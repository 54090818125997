import { serviceGet } from './_factory'

const getById = (id, token) =>
  serviceGet(`/v1/product-accesses/${id}`, undefined, token)

const getList = (query, token) =>
  serviceGet('/v1/product-accesses', query, token)

const getCreatorList = (query, token) =>
  serviceGet('/v1/product-accesses/product-owners', query, token)

const productAccess = {
  getById,
  getList,
  getCreatorList
}

export default productAccess
