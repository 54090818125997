// ----------------------------------------------------------------------

/**
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').Components}
 */
export default function Typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        }
      },
      variants: [
        {
          // means heading x -> general purpose heading
          props: { variant: 'hx' },
          style: { fontSize: 'large', fontWeight: 600 }
        },
        {
          props: { variant: 'input-label' },
          style: { fontSize: 'small', fontWeight: 600 }
        },
        {
          props: { variant: 'shy' },
          style: { fontSize: 'small', color: theme.palette.text.secondary }
        },
        {
          props: { variant: 'label' },
          style: { fontSize: 'small', fontWeight: 'bold' }
        }
      ]
    }
  }
}
