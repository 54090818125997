import { serviceGet } from './_factory'

const getSlugContent = (libraryId, slug, query, token) =>
  serviceGet(`/v1/slugs/${libraryId}/${slug}`, query, token)

const slugService = {
  getSlugContent
}

export default slugService
