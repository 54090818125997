import axios from 'axios'
import { getEndpointUrl, serviceGet, servicePost } from './_factory'

const getList = (query, token) => serviceGet('/v1/invoices', query, token)
const getById = (id, token) =>
  serviceGet(`/v1/invoices/${id}`, undefined, token)

const getStats = (query, token) =>
  serviceGet('/v1/invoices/stats', query, token)

const getAggregateAmount = (query, token) =>
  serviceGet('/v1/invoices/aggregates/amount', query, token)

const getAggregateDate = (query, token) =>
  serviceGet('/v1/invoices/aggregates/date', query, token)

const postCreate = (data, token) => servicePost('/v1/invoices', data, token)

const postCreateBulk = (data, token) =>
  servicePost('/v1/invoices/bulk', data, token)

//
const getPaymentMethods = () => serviceGet('/v1/invoices/payment-methods')

const getExportCSV = (query, token) =>
  axios.get(getEndpointUrl('/v1/invoices/csv'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params: query,
    responseType: 'blob'
  })

const getExportExcel = (query, token) =>
  axios.get(getEndpointUrl('/v1/invoices/excel'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params: query,
    responseType: 'blob'
  })

// multi buyer
const getMultiBuyerInvoiceList = (query, token) =>
  serviceGet('/v1/invoices/bulk', query, token)

const getMultiBuyerInvoice = (id, token) =>
  serviceGet(`/v1/invoices/bulk/${id}`, undefined, token)

const invoiceService = {
  getAggregateAmount,
  getAggregateDate,
  getList,
  getStats,
  getById,
  postCreate,
  postCreateBulk,
  //
  getPaymentMethods,
  getExportCSV,
  getExportExcel,
  getMultiBuyerInvoiceList,
  getMultiBuyerInvoice
}

export default invoiceService
