import axios from 'axios'

import { serviceGet, servicePost } from './_factory'

const getUploadURL = (token) => serviceGet('/v1/images/upload-url', {}, token)

const postUploadToCloudflareImage = (uploadUrl, data) =>
  axios.post(uploadUrl, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

const uploadImage = (data, token) => servicePost('/v1/images', data, token)

const imageService = {
  getUploadURL,
  postUploadToCloudflareImage,
  uploadImage
}

export default imageService
