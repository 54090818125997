import {
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost
} from './_factory'

const getByIdOrSlug = (idOrSlug, token) =>
  serviceGet(`/v1/pages/${idOrSlug}`, undefined, token)

const getList = (query, token) => serviceGet('/v1/pages', query, token)

const postCreate = (data, token) => servicePost('/v1/pages', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/pages/${id}`, data, token)

const deleteRemove = (id, token) =>
  serviceDelete(`/v1/pages/${id}`, undefined, token)

//
const getCount = (query, token) => serviceGet('/v1/pages/count', query, token)

const getAggregateDate = (query, token) =>
  serviceGet('/v1/pages/aggregates/date', query, token)

const pageService = {
  getByIdOrSlug,
  getList,
  postCreate,
  patchUpdate,
  deleteRemove,

  //
  getCount,
  getAggregateDate
}

export default pageService
