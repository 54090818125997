import axios from 'axios'
import { getEndpointUrl, serviceGet, servicePost } from './_factory'

// custom form
const postCreateOrUpdate = (data, token) =>
  servicePost('/v1/custom-forms', data, token)

const getList = (query, token) => serviceGet('/v1/custom-forms', query, token)

// custom form submissions
const postCreateOrUpdateSubmission = (data, token) =>
  servicePost('/v1/custom-forms/submissions', data, token)

const getSubmissionList = (query, token) =>
  serviceGet('/v1/custom-forms/submissions', query, token)

const getSubmissionCount = (query, token) =>
  serviceGet('/v1/custom-forms/submissions/count', query, token)

const getSubmissionExport = (query, token) =>
  axios.get(getEndpointUrl('/v1/custom-forms/submissions/export'), {
    headers: token ? { Authorization: `Bearer ${token}` } : {},
    params: query,
    responseType: 'blob'
  })

const customForm = {
  // custom form
  postCreateOrUpdate,
  getList,
  // custom form submissions
  postCreateOrUpdateSubmission,
  getSubmissionList,
  getSubmissionCount,
  getSubmissionExport
}

export default customForm
