import {
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost
} from './_factory'

const getById = (id, query, token) =>
  serviceGet(`/v1/products/${id}`, query, token)

const getList = (query, token) => serviceGet('/v1/products', query, token)

const postCreate = (data, token) => servicePost('/v1/products', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/products/${id}`, data, token)

const deleteRemove = (id, token) =>
  serviceDelete(`/v1/products/${id}`, undefined, token)

//

const getCount = (query, token) =>
  serviceGet('/v1/products/count', query, token)

const getAggregateDate = (query, token) =>
  serviceGet('/v1/products/aggregates/date', query, token)

const productService = {
  getById,
  getList,
  postCreate,
  patchUpdate,
  deleteRemove,
  //
  getCount,
  getAggregateDate
}

export default productService
