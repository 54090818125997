import {
  serviceGet,
  servicePost,
  serviceDelete,
  servicePatch
} from './_factory'

// promo codes
const postCreate = (data, token) => servicePost('/v1/promo-codes', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/promo-codes/${id}`, data, token)

const getList = (query, token) => serviceGet('/v1/promo-codes', query, token)

const getByIdOrCode = (idOrCode, token) =>
  serviceGet(`/v1/promo-codes/${idOrCode}`, undefined, token)

const deleteRemove = (idOrCode, token) =>
  serviceDelete(`/v1/promo-codes/${idOrCode}`, undefined, token)

const getListByProduct = (id, query, token) =>
  serviceGet(`/v1/promo-codes/products/${id}`, query, token)

const promoCodeService = {
  postCreate,
  patchUpdate,
  getList,
  getListByProduct,
  getByIdOrCode,
  deleteRemove
}

export default promoCodeService
