import { serviceGet } from './_factory'

// ----------------------------------------------------------------------------

const getList = (query, token) => serviceGet('/v1/user-flags', query, token)

const userFlagService = {
  getList
}

export default userFlagService
