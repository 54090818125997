import {
  serviceDelete,
  serviceGet,
  servicePatch,
  servicePost
} from './_factory'

const getList = (query, token) => serviceGet('/v1/earnings/banks', query, token)

const getMethodList = (query, token) =>
  serviceGet('/v1/earnings/banks/methods', query, token)

const postCreate = (data, token) =>
  servicePost('/v1/earnings/banks', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/earnings/banks/${id}`, data, token)

const deleteRemove = (id, token) =>
  serviceDelete(`/v1/earnings/banks/${id}`, undefined, token)

const getReverifyAccount = (id, token) =>
  serviceGet(`/v1/earnings/banks/${id}/verification`, undefined, token)

const payoutAccountService = {
  getList,
  getMethodList: getMethodList,
  postCreate,
  patchUpdate,
  deleteRemove,
  getReverifyAccount
}

export default payoutAccountService
