/**
 * @param {import('@mui/material').Theme} theme
 * @returns {import('@mui/material').Components}
 */
export default function Container(theme) {
  return {
    MuiContainer: {
      variants: [
        {
          props: { variant: 'section' },
          style: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
              paddingTop: theme.spacing(8),
              paddingBottom: theme.spacing(8)
            }
          }
        }
      ]
    }
  }
}
