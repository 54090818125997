import { serviceGet, servicePatch, servicePost } from './_factory'

const getByIdOrBasePath = (idOrBasePath, query, token) =>
  serviceGet(`/v1/libraries/${idOrBasePath}`, query, token)

const getList = (query, token) => serviceGet('/v1/libraries', query, token)

const postCreate = (data, token) => servicePost('/v1/libraries', data, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/libraries/${id}`, data, token)

const libraryService = {
  getByIdOrBasePath,
  getList,
  postCreate,
  patchUpdate
}

export default libraryService
